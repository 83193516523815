<template>
  <configuration-set
    v-if="!!getConfigurationSet()?.courier"
    :components="components"
    :speditor="speditor"
    :current-component="currentComponent"
    :loading="loading"
    @@changeComponent="changeComponent"
    @@save="save"
  >
    <template v-slot:content>
      <ServiceAuthorization
        v-show="currentComponent==='ServiceAuthorization'"
        :is-new="isNew"
      />

      <ServiceParameters
        v-show="currentComponent==='ServiceParameters'"
        :is-new="isNew"
      />

      <AdditionalServices
        v-show="currentComponent==='AdditionalServices'"
        :is-new="isNew"
      />

      <AdditionalFields
        v-show="currentComponent==='AdditionalFields'"
        :is-new="isNew"
      />

      <AdditionalPayments
        v-show="currentComponent==='AdditionalPayments'"
        :is-new="isNew"
      />

      <ContentAndComments
        v-show="currentComponent==='ContentAndComments'"
        :is-new="isNew"
      />
    </template>
  </configuration-set>
</template>

<script>
import ConfigurationSet from '@/components/shared/ConfigurationSet.vue';
import { DHLParcelShop } from '@/constants/Speditors/DHLParcelShop/configuration/DHLParcelShop';
import configurationMixin from '@/mixins/configurationMixin';
import ServiceAuthorization from '@/views/Speditors/DHLParcelShop/Forms/ServiceAuthorization.vue';
import ServiceParameters from '@/views/Speditors/DHLParcelShop/Forms/ServiceParameters.vue';
import AdditionalServices from '@/views/Speditors/DHLParcelShop/Forms/AdditionalServices.vue';
import AdditionalPayments from '@/views/Speditors/DHLParcelShop/Forms/AdditionalPayments.vue';
import ContentAndComments from '@/views/Speditors/DHLParcelShop/Forms/ContentAndComments.vue';
import AdditionalFields from '@/views/Speditors/DHLParcelShop/Forms/AdditionalFields.vue';

export default {
  name: 'DHLParcelShop',
  components: {
    ConfigurationSet,
    ServiceAuthorization,
    ServiceParameters,
    AdditionalPayments,
    AdditionalServices,
    ContentAndComments,
    AdditionalFields,
  },
  mixins: [configurationMixin],
  data: () => ({
    isNew: false,
    speditor: 'DHLParcelShop',
    organizationId: null,
    currentComponent: 'ServiceAuthorization',
    components: [
      { code: 'ServiceAuthorization', name: 'courierSet.serviceAuthorizationName' },
      { code: 'ServiceParameters', name: 'courierSet.serviceParametersName' },
      { code: 'AdditionalServices', name: 'courierSet.additionalServices' },
      { code: 'AdditionalPayments', name: 'courierSet.paymentsSettings' },
      { code: 'ContentAndComments', name: 'courierSet.contentAndComments' },
      { code: 'AdditionalFields', name: 'courierSet.additionalFields' },
    ],
  }),
  methods: {
    setNewConfiguration() {
      this.setConfigSet({ response: DHLParcelShop });
    },
  },
};
</script>
