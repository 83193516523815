<template>
  <div>
    <form-section :title="$t('courierSet.prcelContent')">
      <v-row>
        <v-col cols="6">
          <text-field
            v-model="getDefaultValues.content"
            :title=" $t('courierSet.content')"
            rules="max:30|required"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <text-area-field
            v-model="getDefaultValues.comment"
            :title=" $t('courierSet.remarks')"
            rules="max:100"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <text-area-field
            v-model="getDefaultValues.reference"
            :title=" $t('courierSet.referenceNumber')"
            rules="max:200"
          />
        </v-col>
      </v-row>
    </form-section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
    getDefaultValues() {
      return this.getConfigurationSet().defaultValues;
    },
  },
};
</script>

<style scoped>
</style>
